import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { cards } from '../data/cards';
import { GameService } from './game.service';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss']
})
export class GameComponent implements OnInit {

  cards = [...cards, ...cards];
  clickBlocked = false;

  constructor(
    public game: GameService,
    private router: Router
    ) { }

  ngOnInit(): void {
    this.cards = this.game.shuffleCards(this.cards);
    // this.game.countdown.addEventListener('secondsUpdated', () => {
    //   console.log(this.game.countdown.getTimeValues().toString());
    // });
    this.game.countdown.start();
    this.game.timeEnd$.subscribe((e) => {
      this.gameOver();
    });
  }

  onCardClick(event: any, card: any, index: number) {
    if (this.clickBlocked || !event.target.classList.contains('card')) {
      return;
    }
    if (index === this.game.selectedCard.index) {
      return;
    }
    event.target.classList.add('selected');
    if (this.game.selectedCard.id != null) { // Jedna karta jest już zaznaczona
      this.clickBlocked = true; //Zablokuj odkrywanie kart
      if (this.game.checkMatch(card.id)) { // Karty połączone
        this.cardsMatched(card.id);
      } else { // Karty niepasujące
        this.clickNoMatch(event.target);
      }
    } else { // Żadna karta nie jest zaznaczona
      this.clickUnselected(event.target, card, index);
    }
  }

  cardsMatched(id: any) {
    this.game.resetSelectedCard();
    this.game.addPoint();
    if (this.game.points === 9) {
      this.finishGame();
      setTimeout(() => {
        this.router.navigate(['/end']);
      }, 1000);
    }
    this.clickBlocked = false;
  }

  clickNoMatch(target: any) {
    setTimeout(() => {
      this.game.selectedCard.element.classList.remove('selected');
      target?.classList.remove('selected');
      this.game.resetSelectedCard();
      setTimeout(() => {
        this.clickBlocked = false;
      }, 200); //1500
    }, 300);
  }

  clickUnselected(target: any, card: any, index: number) {
    this.game.selectedCard.id = card.id;
      this.game.selectedCard.element = target;
      this.game.selectedCard.index = index;
      // setTimeout(() => {
      //   this.clickBlocked = false;
      // }, 1000); //1500
  }

  gameOver() {
    this.router.navigate(['/end/gameover']);
  }

  finishGame() {
    const timerAtFinish = this.game.countdown.getTimeValues();
    const time = {
      minutes: this.game.countdown.getConfig().startValues[2] - timerAtFinish.minutes,
      seconds: this.game.countdown.getConfig().startValues[1] - timerAtFinish.seconds,
      totalSeconds: 120 - this.game.countdown.getTotalTimeValues().seconds
    };
    if (time.seconds < 0) {
      time.minutes = time.minutes - 1;
      time.seconds = 60 + time.seconds;
    }
    this.game.addToResults(time, this.game.nick);
    this.game.countdown.stop();
  }

}
