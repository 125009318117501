import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './shared/components';

import { HomeComponent } from './home/home.component';
import { GameComponent } from './game/game.component';
import { EndComponent } from './end/end.component';
import { NickComponent } from './nick/nick.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'nick',
    component: NickComponent
  },
  {
    path: 'game',
    component: GameComponent
  },
  {
    path: 'end',
    component: EndComponent
  },
  {
    path: 'end/:gameover',
    component: EndComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
