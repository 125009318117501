import { Injectable } from '@angular/core';
import Timer from 'easytimer.js';
import { fromEvent, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GameService {

  selectedCard: {id: number | null; element: HTMLElement | null; index: number | null} = {
    id: null,
    element: null,
    index: null
  };
  points = 0;
  nick = '';

  countdown = new Timer({
    countdown: true,
    startValues: {
      minutes: 2,
      seconds: 0
    }
  });

// TODO: Obsłużyć zakończenie timera

  countdown$ = fromEvent(this.countdown, 'secondsUpdated').pipe(
    map((data: any) => {
      const timeString: string = data.detail.timer.getTimeValues().toString();
      const timeArray = timeString.split(':');
      timeArray.shift();
      return timeArray.join(':');

      // const timeArray = data.detail.timer.getTimeValues();
      // return `${timeArray.minutes}:${timeArray.seconds}`;
    })
  );

  timeEnd$ = fromEvent(this.countdown, 'targetAchieved');

  constructor() {

  }

  addPoint() {
    this.points += 1;
  }

  checkMatch(id) {
    return this.selectedCard.id === id;
  }

  resetSelectedCard() {
    this.selectedCard = {
      id: null,
      element: null,
      index: null
    };
  }

  shuffleCards(cards) {
    let currentIndex = cards.length;
    let randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [cards[currentIndex], cards[randomIndex]] = [cards[randomIndex], cards[currentIndex]];
    }
    return cards;
  }

  addToResults(time, nick) {
    const results = JSON.parse(window.localStorage.getItem('results')) || [];
    const singleResult = {
      time,
      nick
    };
    results.push(singleResult);
    window.localStorage.setItem('results', JSON.stringify(results));
  }

  resetGame() {
    this.resetSelectedCard();
    this.countdown.reset();
    this.points = 0;
    this.nick = '';
  }
}
