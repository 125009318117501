export const cards = [
    {
        content: '1.svg',
        id: 1
    },
    {
        content: '2.svg',
        id: 2
    },
    {
        content: '3.svg',
        id: 3
    },
    {
        content: '4.svg',
        id: 4
    },
    {
        content: '5.svg',
        id: 5
    },
    {
        content: '6.svg',
        id: 6
    },
    {
        content: '7.svg',
        id: 7
    },
    {
        content: '8.svg',
        id: 8
    },
    {
        content: '9.svg',
        id: 9
    },
    // {
    //     content: '10.svg',
    //     id: 10
    // },
    // {
    //     content: '11.svg',
    //     id: 11
    // },
    // {
    //     content: '12.svg',
    //     id: 12
    // },
    // {
    //     content: '13.svg',
    //     id: 13
    // },
    // {
    //     content: '14.svg',
    //     id: 14
    // },
    // {
    //     content: '15.svg',
    //     id: 15
    // },
    // {
    //     content: '16.svg',
    //     id: 16
    // },
    // {
    //     content: '17.svg',
    //     id: 17
    // },
    // {
    //     content: '18.svg',
    //     id: 18
    // }
];
