import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  timeStart;

  constructor(private router: Router) { }

  ngOnInit(): void {
    console.log('HomeComponent INIT');
  }

  onPointerDown(e: any) {
    this.timeStart = Date.now();
  }

  onPointerUp(e: any) {
    const timeEnd = Date.now();
    if (timeEnd - this.timeStart >= 2000) {
      e.target.style.transform = 'scale(1.3)';
      window.localStorage.setItem('results', JSON.stringify([]));
      setTimeout(() => {
        e.target.style.transform = 'scale(1)';
      }, 500);
    }
  }

}
