import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GameService } from '../game/game.service';

@Component({
  selector: 'app-end',
  templateUrl: './end.component.html',
  styleUrls: ['./end.component.scss']
})
export class EndComponent implements OnInit, OnDestroy {

  results = [];
  isGameOver = false;
  endTimeout;

  constructor(
    private game: GameService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.isGameOver = !!this.activatedRoute.snapshot.paramMap.get('gameover');
    this.results = JSON.parse(window.localStorage.getItem('results'));
    this.results[this.results.length - 1].current = true;
    this.results = this.results.map((result) => {
      if (result.time.seconds < 10) {
        result.time.seconds = `0${result.time.seconds}`;
      }
      return result;
    });
    this.results = this.results.sort((a, b) => a.time.totalSeconds - b.time.totalSeconds);
    this.results = this.results.slice(0,10);
    this.endTimeout = setTimeout(() => {
      this.router.navigate(['/']);
    }, 15000);
  }

  backHome() {
    this.game.resetGame();
    this.router.navigate(['/']);
  }

  repeatGame() {
    this.game.resetGame();
    this.router.navigate(['/nick']);
  }

  ngOnDestroy() {
    clearTimeout(this.endTimeout);
  }

}
