import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Keyboard from 'simple-keyboard';
import { GameService } from '../game/game.service';

@Component({
  selector: 'app-nick',
  templateUrl: './nick.component.html',
  styleUrls: ['./nick.component.scss']
})
export class NickComponent implements OnInit, AfterViewInit {

  // nick = ['a', 'b', 'c', 'd', 'e'];
  nick = '';
  keyboard: Keyboard;
  isDisabled = true;

  constructor(
    private game: GameService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.keyboard = new Keyboard({
      onChange: input => this.onChange(input),
      onKeyPress: button => this.onKeyPress(button),
      layoutName: 'shift',
      layout: {
        shift: [
          '1 2 3 4 5 6 7 8 9 0 {bksp}',
          'Q W E R T Y U I O P',
          'A S D F G H J K L',
          'Z X C V B N M',
          '{space}'
        ]
      }
    });
  }

  onChange = (input: string) => {
    if (input.length >= 1) {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
    if (input.length > 5) {
      this.keyboard.setInput(`${input.charAt(0)}${input.charAt(1)}${input.charAt(2)}${input.charAt(3)}${input.charAt(4)}`);
      return;
    }
    this.nick = input;
  };

  onKeyPress = (button: string) => {

  };

  onInputChange = (event: any) => {
    this.keyboard.setInput(event.target.value);
  };

  startGame() {
    if (this.isDisabled) {return;}
    this.game.nick = `${this.nick.charAt(0)}${this.nick.charAt(1)}${this.nick.charAt(2)}${this.nick.charAt(3)}${this.nick.charAt(4)}`;
    this.router.navigate(['/game']);
  }

}
